<template>
  <div class="page__wrapper">
    <!-- 整表信息 -->
    <div class="top-desc">
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerCode') }}{{ headInfo.dealer && headInfo.dealer.code }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ $t('dealer_finance.operatingData.reporter') }}{{ headInfo.compiler }}
        </el-col>
      </el-row>
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerName') }}{{ headInfo.dealer && headInfo.dealer.name }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ period }}
        </el-col>
      </el-row>
    </div>
    <ag-grid
      ref="incomeStatementGrid"
      style="width:100%;height:89%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    />
  </div>
</template>

<script>
const BASEURL = {
  list: '/jlr/tt/incomeStatement/wrapper'
}

export default {
  name: 'IncomeStatement',
  props: {
    editable: { type: Boolean, default: true },
    headInfo: { type: Object },
    detailData: { type: Object }
  },
  data () {
    return {
      gridOptions: {},
      frameworkComponents: {},
      isShowDetailDialog: false,
      tableData: [
        {
          accountCategory: '营业收入',
          firstLevelAccount: '主营业务收入',
          secondLevelAccount: '新车销售业务收入',
          thirdLevelAccount: '新车零售收入',
          fourthLevelAccount: '进口车销售收入',
          code: 'code6001010101'
        },
        {
          fourthLevelAccount: '国产车销售收入',
          code: 'code6001010102'
        },
        {
          thirdLevelAccount: '新车非零售收入',
          fourthLevelAccount: '进口车非零售收入',
          fifthLevelAccount: '经销商集团内调拨',
          code: 'code600101020101'
        },
        {
          fifthLevelAccount: '经销商集团间调拨',
          code: 'code600101020102'
        },
        {
          fifthLevelAccount: '批售',
          code: 'code600101020103'
        },
        {
          fifthLevelAccount: '其他',
          code: 'code600101020199'
        },
        {
          fifthLevelAccount: '小计',
          code: 'codessum1'
        },
        {
          fourthLevelAccount: '国产车非零售收入',
          fifthLevelAccount: '经销商集团内调拨',
          code: 'code600101020201'
        },
        {
          fifthLevelAccount: '经销商集团间调拨',
          code: 'code600101020202'
        },
        {
          fifthLevelAccount: '批售',
          code: 'code600101020203'
        },
        {
          fifthLevelAccount: '其他',
          code: 'code600101020299'
        },
        {
          fifthLevelAccount: '小计',
          code: 'codessum2'
        },
        {
          thirdLevelAccount: '新车销售业务收入合计',
          code: 'codessum3'
        },
        {
          secondLevelAccount: '售后收入',
          thirdLevelAccount: '捷豹路虎品牌收入',
          fourthLevelAccount: '钣喷维修收入',
          fifthLevelAccount: '工时收入',
          code: 'code600102010101'
        },
        {
          fifthLevelAccount: '配件收入',
          code: 'code600102010102'
        },
        {
          fourthLevelAccount: '机电维修收入',
          fifthLevelAccount: '工时收入',
          code: 'code600102010201'
        },
        {
          fifthLevelAccount: '配件收入',
          code: 'code600102010202'
        },
        {
          fourthLevelAccount: '保修收入',
          fifthLevelAccount: '工时收入',
          code: 'code600102010301'
        },
        {
          fifthLevelAccount: '配件收入',
          code: 'code600102010302'
        },
        {
          fourthLevelAccount: '配件柜台销售收入',
          code: 'code6001020104'
        },
        {
          fourthLevelAccount: '售后其他业务收入',
          code: 'code6001020105'
        },
        {
          thirdLevelAccount: '非捷豹路虎品牌收入',
          code: 'code60010202'
        },
        {
          thirdLevelAccount: '售后收入合计',
          code: 'codessum4'
        },
        {
          secondLevelAccount: '主营业务收入合计',
          code: 'codessum5'
        },
        {
          firstLevelAccount: '水平业务收入',
          secondLevelAccount: '新车衍生业务收入',
          thirdLevelAccount: '新车金融服务收入',
          fourthLevelAccount: '进口车新车金融服务收入',
          code: 'code6051010101'
        },
        {
          fourthLevelAccount: '国产车新车金融服务收入',
          code: 'code6051010102'
        },
        {
          thirdLevelAccount: '新车保险服务收入',
          fourthLevelAccount: '进口车新车保险服务收入',
          code: 'code6051010201'
        },
        {
          fourthLevelAccount: '国产车新车保险服务收入',
          code: 'code6051010202'
        },
        {
          thirdLevelAccount: '新车车辆用品销售收入',
          fourthLevelAccount: '进口车新车车辆用品销售收入',
          code: 'code6051010301'
        },
        {
          fourthLevelAccount: '国产车新车车辆用品销售收入',
          code: 'code6051010302'
        },
        {
          thirdLevelAccount: '新车延保销售收入',
          fourthLevelAccount: '进口车新车延保销售收入',
          code: 'code6051010401'
        },
        {
          fourthLevelAccount: '国产车新车延保销售收入',
          code: 'code6051010402'
        },
        {
          thirdLevelAccount: '新车其他服务收支',
          fourthLevelAccount: '进口车新车其他服务收支',
          code: 'code6051010501'
        },
        {
          fourthLevelAccount: '国产车新车其他服务收支',
          code: 'code6051010502'
        },
        {
          thirdLevelAccount: '新车衍生业务收入合计',
          code: 'codessum6'
        },
        {
          secondLevelAccount: '售后衍生业务收入',
          thirdLevelAccount: '售后车辆用品销售收入',
          code: 'code60510201'
        },
        {
          thirdLevelAccount: '售后延保销售收入',
          code: 'code60510202'
        },
        {
          thirdLevelAccount: '售后续保服务收入',
          code: 'code60510203'
        },
        {
          thirdLevelAccount: '售后衍生业务收入合计',
          code: 'codessum7'
        },
        {
          secondLevelAccount: '二手车销售毛利',
          thirdLevelAccount: '认证二手车零售毛利',
          code: 'code60510301'
        },
        {
          thirdLevelAccount: '非认证二手车转售毛利',
          code: 'code60510302'
        },
        {
          thirdLevelAccount: '二手车销售毛利合计',
          code: 'codessum8'
        },
        {
          secondLevelAccount: '其他业务收入',
          code: 'code605104'
        },
        {
          secondLevelAccount: '水平业务收入合计',
          code: 'codessum9'
        },
        {
          firstLevelAccount: '营业收入合计',
          code: 'codessum10'
        },
        {
          accountCategory: '营业成本',
          firstLevelAccount: '主营业务成本',
          secondLevelAccount: '新车销售业务成本',
          thirdLevelAccount: '新车零售成本',
          fourthLevelAccount: '零售进口车成本',
          code: 'code6401010101'
        },
        {
          fourthLevelAccount: '零售国产车成本',
          code: 'code6401010102'
        },
        {
          thirdLevelAccount: '新车非零售成本',
          fourthLevelAccount: '进口车非零售成本',
          fifthLevelAccount: '经销商集团内调拨',
          code: 'code640101020101'
        },
        {
          fifthLevelAccount: '经销商集团间调拨',
          code: 'code640101020102'
        },
        {
          fifthLevelAccount: '批售',
          code: 'code640101020103'
        },
        {
          fifthLevelAccount: '其他',
          code: 'code640101020199'
        },
        {
          fifthLevelAccount: '小计',
          code: 'codessum11'
        },
        {
          fourthLevelAccount: '国产车非零售成本',
          fifthLevelAccount: '经销商集团内调拨',
          code: 'code640101020201'
        },
        {
          fifthLevelAccount: '经销商集团间调拨',
          code: 'code640101020202'
        },
        {
          fifthLevelAccount: '批售',
          code: 'code640101020203'
        },
        {
          fifthLevelAccount: '其他',
          code: 'code640101020299'
        },
        {
          fifthLevelAccount: '小计',
          code: 'codessum12'
        },
        {
          thirdLevelAccount: '新车销售返利（VRD）',
          fourthLevelAccount: '进口车VRD返利',
          code: 'code6401010301'
        },
        {
          fourthLevelAccount: '国产车VRD返利',
          code: 'code6401010302'
        },
        {
          thirdLevelAccount: '新车销售返利（VME）',
          fourthLevelAccount: '进口车VME返利',
          code: 'code6401010401'
        },
        {
          fourthLevelAccount: '国产车VME返利',
          code: 'code6401010402'
        },
        {
          thirdLevelAccount: '新车销售业务成本合计',
          code: 'codessum13'
        },
        {
          secondLevelAccount: '售后成本',
          thirdLevelAccount: '捷豹路虎品牌成本',
          fourthLevelAccount: '钣喷维修成本',
          fifthLevelAccount: '工时成本',
          code: 'code640102010101'
        },
        {
          fifthLevelAccount: '配件成本',
          code: 'code640102010102'
        },
        {
          fourthLevelAccount: '机电维修成本',
          fifthLevelAccount: '工时成本',
          code: 'code640102010201'
        },
        {
          fifthLevelAccount: '配件成本',
          code: 'code640102010202'
        },
        {
          fourthLevelAccount: '保修成本',
          fifthLevelAccount: '工时成本',
          code: 'code640102010301'
        },
        {
          fifthLevelAccount: '配件成本',
          code: 'code640102010302'
        },
        {
          fourthLevelAccount: '配件柜台销售成本',
          code: 'code6401020104'
        },
        {
          fourthLevelAccount: '售后其他业务成本',
          code: 'code6401020105'
        },
        {
          thirdLevelAccount: '售后返利',
          code: 'code64010202'
        },
        {
          thirdLevelAccount: '非捷豹路虎品牌成本',
          code: 'code64010203'
        },
        {
          thirdLevelAccount: '售后成本合计',
          code: 'codessum14'
        },
        {
          secondLevelAccount: '主营业务成本合计',
          code: 'codessum15'
        },
        {
          firstLevelAccount: '水平业务成本',
          secondLevelAccount: '新车衍生业务成本',
          thirdLevelAccount: '新车车辆用品销售成本',
          fourthLevelAccount: '进口车新车车辆用品销售成本',
          code: 'code6402010101'
        },
        {
          fourthLevelAccount: '国产车新车车辆用品销售成本',
          code: 'code6402010102'
        },
        {
          thirdLevelAccount: '新车延保销售成本',
          fourthLevelAccount: '进口车新车延保销售成本',
          code: 'code6402010201'
        },
        {
          fourthLevelAccount: '国产车新车延保销售成本',
          code: 'code6402010202'
        },
        {
          thirdLevelAccount: '新车油卡赠送成本',
          fourthLevelAccount: '进口车新车油卡赠送成本',
          code: 'code6402010301'
        },
        {
          fourthLevelAccount: '国产车新车油卡赠送成本',
          code: 'code6402010302'
        },
        {
          thirdLevelAccount: '新车销售赠送成本',
          fourthLevelAccount: '进口车新车销售赠送成本',
          code: 'code6402010401'
        },
        {
          fourthLevelAccount: '国产车新车销售赠送成本',
          code: 'code6402010402'
        },
        {
          thirdLevelAccount: '新车衍生业务成本合计',
          code: 'codessum16'
        },
        {
          secondLevelAccount: '售后衍生业务成本',
          thirdLevelAccount: '售后车辆用品销售成本',
          code: 'code64020201'
        },
        {
          thirdLevelAccount: '售后延保销售成本',
          code: 'code64020202'
        },
        {
          thirdLevelAccount: '售后衍生业务成本合计',
          code: 'codessum17'
        },
        {
          secondLevelAccount: '其他业务成本',
          code: 'code640203'
        },
        {
          secondLevelAccount: '水平业务成本合计',
          code: 'codessum18'
        },
        {
          firstLevelAccount: '营业成本合计',
          code: 'codessum19'
        },
        {
          accountCategory: '期间费用',
          firstLevelAccount: '新车部门费用',
          secondLevelAccount: '新车直接变动费用',
          thirdLevelAccount: '新车部门人员销售佣金/奖金',
          code: 'code66010101'
        },
        {
          secondLevelAccount: '新车直接固定费用',
          thirdLevelAccount: '新车部门薪酬和福利费',
          code: 'code66010201'
        },
        {
          thirdLevelAccount: '新车销售促销费用',
          code: 'code66010202'
        },
        {
          thirdLevelAccount: '新车部门业务招待费',
          code: 'code66010203'
        },
        {
          thirdLevelAccount: '新车部门差旅费',
          code: 'code66010204'
        },
        {
          thirdLevelAccount: '新车部门人员培训费用',
          code: 'code66010205'
        },
        {
          thirdLevelAccount: '新车部门市场费用',
          // fourthLevelAccount: '新车部门广告宣传费',
          code: 'code66010206'
        },
        // {
        //   fourthLevelAccount: '新车部门市场活动费',
        //   code: 'code6601020602'
        // },
        // {
        //   fourthLevelAccount: '新车部门其他市场费用',
        //   code: 'code6601020603'
        // },
        {
          thirdLevelAccount: '新车运输费用',
          code: 'code66010207'
        },
        {
          thirdLevelAccount: '试乘试驾车使用费用',
          code: 'code66010208'
        },
        {
          thirdLevelAccount: '试乘试驾车折旧费用',
          code: 'code66010209'
        },
        {
          thirdLevelAccount: '新车采购融资费用',
          code: 'code66010210'
        },
        {
          thirdLevelAccount: '银行监管费',
          code: 'code66010211'
        },
        {
          thirdLevelAccount: '新车部门其他费用',
          code: 'code66010299'
        },
        {
          thirdLevelAccount: '新车直接固定费用合计',
          code: 'codessum20'
        },
        {
          secondLevelAccount: '新车部门费用合计',
          code: 'codessum21'
        },
        {
          firstLevelAccount: '售后部门费用',
          secondLevelAccount: '售后直接变动费用',
          thirdLevelAccount: '售后部门人员销售佣金/奖金',
          code: 'code66020101'
        },
        {
          secondLevelAccount: '售后直接固定费用',
          thirdLevelAccount: '售后部门薪酬和福利费',
          code: 'code66020201'
        },
        {
          thirdLevelAccount: '售后部门市场费用',
          // fourthLevelAccount: '广告宣传费',
          code: 'code66020202'
        },
        // {
        //   fourthLevelAccount: '市场活动费',
        //   code: 'code6602020202'
        // },
        // {
        //   fourthLevelAccount: '其他市场费用',
        //   code: 'code6602020203'
        // },
        {
          thirdLevelAccount: '售后部门业务招待费',
          code: 'code66020203'
        },
        {
          thirdLevelAccount: '售后促销费',
          code: 'code66020204'
        },
        {
          thirdLevelAccount: '售后部门差旅费',
          code: 'code66020205'
        },
        {
          thirdLevelAccount: '售后部门人员培训费用',
          code: 'code66020206'
        },
        {
          thirdLevelAccount: '车辆使用费',
          code: 'code66020207'
        },
        {
          thirdLevelAccount: '售后设备保险费用',
          code: 'code66020208'
        },
        {
          thirdLevelAccount: '机器设备及其他售后专用设备折旧费用',
          code: 'code66020209'
        },
        {
          thirdLevelAccount: '售后部门其他费用',
          code: 'code66020299'
        },
        {
          thirdLevelAccount: '售后直接固定费用合计',
          code: 'codessum22'
        },
        {
          secondLevelAccount: '售后部门费用合计',
          code: 'codessum23'
        },
        {
          firstLevelAccount: '二手车业务费用',
          secondLevelAccount: '二手车部门直接变动费用',
          thirdLevelAccount: '二手车业务人员销售佣金/奖金',
          code: 'code66030101'
        },
        {
          secondLevelAccount: '二手车部门直接固定费用',
          thirdLevelAccount: '二手车业务人员薪酬和福利费',
          code: 'code66030201'
        },
        {
          thirdLevelAccount: '二手车业务其他费用',
          code: 'code66030299'
        },
        {
          secondLevelAccount: '二手车业务费用合计',
          code: 'codessum24'
        },
        {
          firstLevelAccount: '利息费用',
          secondLevelAccount: '利息收入',
          code: 'code660401'
        },
        {
          secondLevelAccount: '利息支出',
          code: 'code660402'
        },
        {
          secondLevelAccount: '利息费用合计',
          code: 'codessum25'
        },
        {
          secondLevelAccount: '房屋、土地租金',
          code: 'code660501'
        },
        {
          secondLevelAccount: '固定资产折旧',
          code: 'code660502'
        },
        {
          secondLevelAccount: '无形资产摊销',
          code: 'code660504'
        },
        {
          firstLevelAccount: '间接费用',
          secondLevelAccount: '行政部门人员薪酬和福利费',
          // thirdLevelAccount: '行政及管理类岗位',
          code: 'code660505'
        },
        // {
        //   thirdLevelAccount: '财务类岗位',
        //   code: 'code66050503'
        // },
        // {
        //   thirdLevelAccount: '市场部岗位',
        //   code: 'code66050504'
        // },
        // {
        //   thirdLevelAccount: '客服部岗位',
        //   code: 'code66050505'
        // },
        // {
        //   thirdLevelAccount: '其他岗位',
        //   code: 'code66050599'
        // },
        // {
        //   thirdLevelAccount: '小计',
        //   code: 'codessum26'
        // },
        {
          secondLevelAccount: '银行业务费用',
          // thirdLevelAccount: '汇兑损益',
          code: 'code660506'
        },
        // {
        //   thirdLevelAccount: '银行承兑手续费',
        //   code: 'code66050602'
        // },
        // {
        //   thirdLevelAccount: 'POS手续费',
        //   code: 'code66050603'
        // },
        // {
        //   thirdLevelAccount: '汇款手续费',
        //   code: 'code66050604'
        // },
        // {
        //   thirdLevelAccount: '其他银行相关手续费',
        //   code: 'code66050699'
        // },
        // {
        //   thirdLevelAccount: '小计',
        //   code: 'codessum27'
        // },
        {
          secondLevelAccount: '市场费用',
          code: 'code660507'
        },
        {
          secondLevelAccount: '其他公司行政管理费用',
          code: 'code660599'
        },
        {
          secondLevelAccount: '间接费用合计',
          code: 'codessum28'
        },
        {
          firstLevelAccount: '期间费用合计',
          code: 'codessum29'
        },
        {
          accountCategory: '税金及附加',
          code: 'code6403'
        },
        {
          accountCategory: '投资收益',
          code: 'code6111'
        },
        {
          accountCategory: '资产减值损失',
          code: 'code6701'
        },
        {
          accountCategory: '营业利润',
          code: 'codessum30'
        },
        {
          accountCategory: '营业外收入',
          code: 'code6301',
          fifthLevelAccount: '加：'
        },
        {
          firstLevelAccount: '其中：建店补贴',
          code: 'code630101'
        },
        {
          accountCategory: '营业外支出',
          code: 'code6711',
          fifthLevelAccount: '减：'
        },
        {
          accountCategory: '利润总额',
          code: 'codessum31'
        },
        {
          accountCategory: '以前年度损益调整',
          code: 'code6901'
        },
        {
          accountCategory: '所得税',
          code: 'code6801'
        },
        {
          accountCategory: '净利润',
          code: 'codessum32'
        },
        {
          accountCategory: '少数股东损益',
          secondLevelAccount: '*',
          code: 'code6902'
        },
        {
          accountCategory: '归属于母公司的净利润',
          code: 'codessum33'
        },
        {
          accountCategory: '期初未分配利润',
          code: 'code6904',
          fifthLevelAccount: '加：'
        },
        {
          accountCategory: '提取盈余公积',
          code: 'code410401',
          fifthLevelAccount: '减：'
        },
        {
          accountCategory: '应付普通股股利',
          code: 'code410404',
          fifthLevelAccount: '减：'
        },
        {
          accountCategory: '特殊损益调整',
          code: 'code9999',
          fifthLevelAccount: '减：'
        },
        {
          accountCategory: '未分配利润',
          code: 'codessum34'
        }
      ]
    }
  },
  computed: {
    period () {
      return this.headInfo.period ? this.$moment(this.headInfo.period, 'YYYYMM').format('YYYY年MM月') : ''
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.incomeStatement.accountCategory'),
          field: 'accountCategory',
          colSpan: params => {
            const arr1 = [
              '税金及附加',
              '投资收益',
              '资产减值损失',
              '营业利润',
              '利润总额',
              '以前年度损益调整',
              '所得税',
              '净利润',
              '归属于母公司的净利润',
              '未分配利润'
            ]
            const arr2 = [
              '营业外收入',
              '营业外支出',
              '期初未分配利润',
              '提取盈余公积',
              '应付普通股股利',
              '特殊损益调整'
            ]
            const isA = arr1.indexOf(params.data.accountCategory) > -1
            const isB = arr2.indexOf(params.data.accountCategory) > -1
            const isC = params.data.accountCategory === '少数股东损益'
            return isA ? 6 : (isB ? 5 : (isC ? 2 : 1))
          },
          rowSpan: params => {
            const isA = params.data.accountCategory === '营业收入'
            const isB = params.data.accountCategory === '营业成本'
            const isC = params.data.accountCategory === '期间费用'
            return isA ? 45 : (isB ? 44 : (isC ? 45 : 1))
          },
          cellStyle: params => {
            const arr1 = ['营业收入', '营业成本', '期间费用']
            const isA = arr1.indexOf(params.data.accountCategory) > -1
            const arr2 = ['营业利润', '利润总额', '净利润', '未分配利润']
            const isB = arr2.indexOf(params.data.accountCategory) > -1
            const isC = params.data.accountCategory === '其中：建店补贴'
            return isA ? { background: 'white' } : (isB ? { fontWeight: 600, textAlign: 'center', fontSize: '14px' } : (isC ? { paddingLeft: '100px' } : {}))
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.firstLevelAccount'),
          field: 'firstLevelAccount',
          rowSpan: params => {
            const isA = params.data.firstLevelAccount === '主营业务收入'
            const isB = params.data.firstLevelAccount === '水平业务收入'
            const isC = params.data.firstLevelAccount === '主营业务成本'
            const isD = params.data.firstLevelAccount === '水平业务成本'
            const isE = params.data.firstLevelAccount === '新车部门费用'
            const isF = params.data.firstLevelAccount === '间接费用'
            const isG = params.data.firstLevelAccount === '二手车业务费用'
            const isH = params.data.firstLevelAccount === '利息费用'
            const isI = params.data.firstLevelAccount === '售后部门费用'
            return isA ? 24 : (isB ? 19 : (isC ? 28 : (isD ? 13 : (isE ? 14 : isF ? 4 : (isG ? 3 : (isH ? 5 : (isI ? 12 : 1)))))))
          },
          colSpan: params => {
            const arr = ['营业收入合计', '营业成本合计', '期间费用合计', '其中：建店补贴']
            return arr.indexOf(params.data.firstLevelAccount) > -1 ? 5 : 1
          },
          cellStyle: params => {
            const arr = [
              '主营业务收入',
              '水平业务收入',
              '主营业务成本',
              '水平业务成本',
              '新车部门费用',
              '售后部门费用',
              '二手车业务费用',
              '利息费用',
              '间接费用'
            ]
            const arr2 = ['营业收入合计', '营业成本合计', '期间费用合计']
            const isA = arr.indexOf(params.data.firstLevelAccount) > -1
            const isB = arr2.indexOf(params.data.firstLevelAccount) > -1
            return isA ? { background: 'white' } : (isB ? { fontWeight: 600, textAlign: 'center' } : {})
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.secondLevelAccount'),
          field: 'secondLevelAccount',
          rowSpan: params => {
            const arr1 = ['售后成本', '新车衍生业务收入']
            const arr2 = ['银行业务费用']
            const arr3 = ['售后衍生业务成本', '二手车销售毛利']
            const isA = params.data.secondLevelAccount === '新车销售业务收入'
            const isB = params.data.secondLevelAccount === '售后收入'
            const isC = arr1.indexOf(params.data.secondLevelAccount) > -1
            const isD = params.data.secondLevelAccount === '售后衍生业务收入'
            const isE = params.data.secondLevelAccount === '新车销售业务成本'
            const isF = params.data.secondLevelAccount === '新车衍生业务成本'
            const isG = arr3.indexOf(params.data.secondLevelAccount) > -1
            const isH = params.data.secondLevelAccount === '新车直接固定费用'
            const isI = arr2.indexOf(params.data.secondLevelAccount) > -1
            const isJ = params.data.secondLevelAccount === '售后直接固定费用'
            return isA ? 13 : (isB ? 9 : (isC ? 10 : (isD ? 3 : (isE ? 16 : (isF ? 8 : (isG ? 2 : (isH ? 12 : (isI ? 5 : (isJ ? 10 : 1)))))))))
          },
          colSpan: params => {
            const arr = [
              '主营业务收入合计',
              '其他业务收入',
              '水平业务收入合计',
              '主营业务成本合计',
              '其他业务成本',
              '水平业务成本合计',
              '新车部门费用合计',
              '售后部门费用合计',
              '二手车业务费用合计',
              '利息收入',
              '利息支出',
              '利息费用合计',
              '房屋、土地租金',
              '固定资产折旧',
              '无形资产摊销',
              '市场费用',
              '其他公司行政管理费用',
              '行政部门人员薪酬和福利费',
              '银行业务费用',
              '间接费用合计',
              '*'
            ]
            return arr.indexOf(params.data.secondLevelAccount) > -1 ? 4 : 1
          },
          cellStyle: params => {
            const arr = [
              '新车销售业务收入',
              '售后直接固定费用',
              '售后成本',
              '新车衍生业务收入',
              '行政部门人员薪酬和福利费',
              // '银行业务费用',
              '售后收入',
              '售后衍生业务收入',
              '新车销售业务成本',
              '新车衍生业务成本',
              '售后衍生业务成本',
              '新车直接固定费用',
              '二手车部门直接固定费用',
              '二手车销售毛利'
            ]
            const arr2 = [
              '主营业务收入合计',
              '水平业务收入合计',
              '主营业务成本合计',
              '水平业务成本合计',
              '新车部门费用合计',
              '售后部门费用合计',
              '二手车业务费用合计',
              '利息费用合计',
              '间接费用合计'
            ]
            const isA = arr.indexOf(params.data.secondLevelAccount) > -1
            const isB = arr2.indexOf(params.data.secondLevelAccount) > -1
            return isA ? { background: 'white' } : (isB ? { fontWeight: 600, textAlign: 'right' } : {})
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.thirdLevelAccount'),
          field: 'thirdLevelAccount',
          rowSpan: params => {
            const arr = [
              '新车零售收入',
              '新车金融服务收入',
              '新车保险服务收入',
              '新车车辆用品销售收入',
              '新车延保销售收入',
              '新车其他服务收支',
              '新车零售成本',
              '新车销售返利（VRD）',
              '新车销售返利（VME）',
              '新车车辆用品销售成本',
              '新车延保销售成本',
              '新车油卡赠送成本',
              '新车销售赠送成本'
            ]
            const arr2 = [
              '新车非零售收入',
              '新车非零售成本'
            ]
            const arr3 = [
              '捷豹路虎品牌收入',
              '捷豹路虎品牌成本'
            ]
            const arr4 = ['新车部门人员销售佣金']
            const isA = arr.indexOf(params.data.thirdLevelAccount) > -1
            const isB = arr2.indexOf(params.data.thirdLevelAccount) > -1
            const isC = arr3.indexOf(params.data.thirdLevelAccount) > -1
            const isD = arr4.indexOf(params.data.thirdLevelAccount) > -1
            return isA ? 2 : (isB ? 9 : (isC ? 7 : (isD ? 2 : 1)))
          },
          cellStyle: params => {
            const arr = [
              '新车零售收入',
              '新车非零售收入',
              '新车非零售成本',
              '捷豹路虎品牌收入',
              '捷豹路虎品牌成本',
              '新车部门人员销售佣金',
              '新车部门市场费用'
            ]
            const arr2 = [
              '新车金融服务收入',
              '新车保险服务收入',
              '新车车辆用品销售收入',
              '新车延保销售收入',
              '新车其他服务收支',
              '新车零售成本',
              '新车销售返利（VRD）',
              '新车销售返利（VME）',
              '新车车辆用品销售成本',
              '新车延保销售成本',
              '新车油卡赠送成本',
              '新车销售赠送成本'
            ]
            const arr3 = [
              '新车销售业务收入合计',
              '新车销售业务成本合计',
              '售后收入合计',
              '售后成本合计',
              '新车衍生业务收入合计',
              '新车衍生业务成本合计',
              '售后衍生业务收入合计',
              '售后衍生业务成本合计',
              '二手车销售毛利合计',
              '水平业务收入合计',
              '水平业务成本合计',
              '营业收入合计',
              '新车直接固定费用合计',
              '售后直接固定费用合计',
              '小计'
            ]
            const isA = arr.indexOf(params.data.thirdLevelAccount) > -1
            const isB = arr2.indexOf(params.data.thirdLevelAccount) > -1
            const isC = arr3.indexOf(params.data.thirdLevelAccount) > -1
            const style1 = { background: 'white' }
            const style2 = { background: 'white', borderBottom: '1px solid #e4e7e8' }
            const style3 = { fontWeight: 600, textAlign: 'right' }
            return isA ? style1 : (isB ? style2 : (isC ? style3 : {}))
          },
          colSpan: params => {
            const arr = [
              '新车销售业务收入合计',
              '售后收入合计',
              '新车衍生业务收入合计',
              '售后车辆用品销售收入',
              '售后延保销售收入',
              '售后续保服务收入',
              '售后衍生业务收入合计',
              '认证二手车零售毛利',
              '非认证二手车转售毛利',
              '二手车销售毛利合计',
              '新车销售业务成本合计',
              '售后返利',
              '非捷豹路虎品牌成本',
              '售后成本合计',
              '新车衍生业务成本合计',
              '售后车辆用品销售成本',
              '售后延保销售成本',
              '售后衍生业务成本合计',
              '新车部门人员销售佣金/奖金',
              '新车部门薪酬和福利费',
              '新车销售促销费用',
              '新车部门业务招待费',
              '新车部门差旅费',
              '新车部门人员培训费用',
              '非捷豹路虎品牌收入',
              '售后车辆用品销售成本',
              '售后延保销售成本',
              '售后衍生业务成本合计',
              '新车运输费用',
              '试乘试驾车使用费用',
              '试乘试驾车折旧费用',
              '新车采购融资费用',
              '银行监管费',
              '新车部门其他费用',
              '新车直接固定费用合计',
              '售后部门人员销售佣金/奖金',
              '售后部门薪酬和福利费',
              '售后部门业务招待费',
              '售后促销费',
              '售后部门差旅费',
              '售后部门人员培训费用',
              '车辆使用费',
              '售后部门市场费用',
              '售后设备保险费用',
              '机器设备及其他售后专用设备折旧费用',
              '售后部门其他费用',
              '售后直接固定费用合计',
              '二手车业务人员销售佣金/奖金',
              '二手车业务人员薪酬和福利费',
              '二手车业务其他费用',
              '行政及管理类岗位',
              '财务类岗位',
              '市场部岗位',
              '客服部岗位',
              '其他岗位',
              '小计',
              '汇兑损益',
              '银行承兑手续费',
              'POS手续费',
              '汇款手续费',
              '其他银行相关手续费',
              '新车部门市场费用'
            ]
            return arr.indexOf(params.data.thirdLevelAccount) > -1 ? 3 : 1
          },
          minWidth: 150
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.fourthLevelAccount'),
          field: 'fourthLevelAccount',
          colSpan: params => {
            const arr = [
              '进口车销售收入',
              '国产车销售收入',
              '配件柜台销售收入',
              '售后其他业务收入',
              '进口车新车金融服务收入',
              '国产车新车金融服务收入',
              '进口车新车保险服务收入',
              '国产车新车保险服务收入',
              '进口车新车车辆用品销售收入',
              '国产车新车车辆用品销售收入',
              '进口车新车延保销售收入',
              '国产车新车延保销售收入',
              '进口车新车其他服务收支',
              '国产车新车其他服务收支',
              '零售进口车成本',
              '零售国产车成本',
              '配件柜台销售成本',
              '售后其他业务成本',
              '进口车新车车辆用品销售成本',
              '国产车新车车辆用品销售成本',
              '进口车新车延保销售成本',
              '国产车新车延保销售成本',
              '进口车新车油卡赠送成本',
              '国产车新车油卡赠送成本',
              '进口车新车销售赠送成本',
              '国产车新车销售赠送成本',
              '新车部门广告宣传费',
              '新车部门市场活动费',
              '新车部门其他市场费用',
              '广告宣传费',
              '市场活动费',
              '其他市场费用'
            ]
            return arr.indexOf(params.data.fourthLevelAccount) > -1 ? 2 : 1
          },
          rowSpan: params => {
            const arr = [
              '进口车非零售收入',
              '国产车非零售收入',
              '进口车非零售成本',
              '国产车非零售成本'
            ]
            const isA = arr.indexOf(params.data.fourthLevelAccount) > -1
            const arr2 = [
              '钣喷维修收入',
              '机电维修收入',
              '保修收入',
              '钣喷维修成本',
              '机电维修成本',
              '保修成本'
            ]
            const isB = arr2.indexOf(params.data.fourthLevelAccount) > -1
            return isA ? 4 : (isB ? 2 : 1)
          },
          cellStyle: params => {
            const arr = [
              '进口车非零售收入',
              '国产车非零售收入',
              '进口车非零售成本',
              '国产车非零售成本'
            ]
            const arr2 = [
              '钣喷维修收入',
              '机电维修收入',
              '保修收入',
              '钣喷维修成本',
              '机电维修成本',
              '保修成本'
            ]
            const isA = arr.indexOf(params.data.fourthLevelAccount) > -1
            const isB = arr2.indexOf(params.data.fourthLevelAccount) > -1
            return isA ? { background: 'white' } : (isB ? { background: 'white', borderBottom: '1px solid #e4e7e8' } : {})
          },
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.fifthLevelAccount'),
          field: 'fifthLevelAccount',
          cellStyle: params => {
            return params.data.fifthLevelAccount === '小计' ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          minWidth: 140
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.benchmarkReport'),
          children: [
            {
              headerName: this.period,
              field: 'beginBalance',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.adjustment'),
          children: [
            {
              headerName: this.$t('dealer_finance.incomeStatement.lend'),
              field: 'debit',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            },
            {
              headerName: this.$t('dealer_finance.incomeStatement.loan'),
              field: 'credit',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.adjustmentReport'),
          children: [
            {
              headerName: this.period,
              field: 'endBalance',
              valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
              minWidth: 120
            }
          ]
        },
        {
          headerName: this.$t('dealer_finance.incomeStatement.remark'),
          field: 'remark',
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          minWidth: 200
        }
      ]
    }
  },
  watch: {
    headInfo (info) {
      this.$refs.incomeStatementGrid.redrawGrid(this.columnDefs)
    }
  },
  beforeMount () {
    this.gridOptions.suppressRowTransform = true
    this.gridOptions.getRowStyle = params => {
      const arr = [45, 89, 133, 140, 141, 146, 151]
      if (arr.indexOf(params.node.rowIndex) > -1) {
        return { borderTopColor: '#999' }
      }
    }
  },
  mounted () { },
  methods: {
    handleDataSearch () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] })
        .then(resp => {
          const respData = resp.data
          let result
          const rs = this.tableData.map(ele => {
            result = {}
            Object.keys(respData).forEach(key => {
              if (key === ele.code) { result = Object.assign({}, ele, respData[key]) }
            })
            return Object.keys(result).length ? result : ele
          })
          this.$set(this, 'tableData', rs)
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
    padding-right: 8px;
    .top-desc {
      font-size: 12px;
      padding: 4px 4px;
    }
  }
</style>
